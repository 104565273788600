<template>
  <div class="main_box">
    <div class="main_boxtop">
      <p>领取成功</p>
    </div>
    <div class="tline">

    </div>
    <div class="line">
      <img src="../../../assets/image/lqcg.png" alt="">
    </div>
    <div>
      <p class="gxmsg">恭喜您</p>
      <p class="dhmmsg">兑换码已经领取成功</p>
    </div>
    <div class="iptdiv">
      <input v-model="$route.query.id" disabled type="text">
      <button id="copy_text" :data-clipboard-text="dhCode" @click="copy()">点击复制兑换码</button>
    </div>
    <div class="lines">

    </div>
    <div v-if="shows" class="foot1">
      <p>进入个人中心-兑换码中使用</p>
      <div class="btnh1">
        <p class="goindex" @click="gocodeindex()">去兑换</p>
        <img class="raww" src="../../../assets/image/raww.png" alt="">
      </div>
    </div>
    <div v-else class="foot">
      <p>请您使用手机号{{ $route.query.phone.substr(0, 3) + '****' + $route.query.phone.substr(7, 11) }}</p>
      <p>登录“筑龙学社APP”进入个人中心-兑换码中使用</p>
      <div class="btnh">
        <p class="goindex" @click="gozlindex()">回首页</p>
        <img class="raww" src="../../../assets/image/raww.png" alt="">

      </div>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      islog: false,
      shows: false,
      dhCode: ''
    }
  },
  mounted() {
    if (Cookie.get('uid')) {
      console.log(Cookie.get('uid'))
      this.shows = true
    }
  },
  methods: {
    gozlindex() {
      window.open('https://www.zhulong.com/')
    },
    gocodeindex() {
      this.$router.push({
        path: 'ExchangeCode',
        query: {

        }
      })
    },
    // 复制
    copy() {
      this.dhCode = this.$route.query.id
      var clipboard = new Clipboard('#copy_text')

      clipboard.on('success', (e) => {
        console.log(e.text)
        this.$message({
          message: '已复制兑换码到剪切板',
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#copy_text{
    cursor: pointer;
    font-size: 20px;
}
.raww{
    width: 10px;
    height: 15px;
    margin: 13px 0 0 10px;
}
.btnh{
    display: flex;
    width: 124px;
    height: 44px;
    border: 1px solid #EE2E2E;
    border-radius: 22px;
    margin: 0 auto;
    margin-top: 60px;
    cursor: pointer;
    .goindex{
        color: #ee2e2e !important;
        font-size: 16px !important;
        text-align: left;
        line-height: 41px;
        padding: 0 0 0 30px !important;
    }
}
.btnh1{
    display: flex;
    width: 124px;
    height: 44px;
    border: 1px solid #EE2E2E;
    border-radius: 22px;
    margin: 0 auto;
    margin-top: 90px;
    cursor: pointer;
    .goindex{
        color: #ee2e2e !important;
        font-size: 16px !important;
        text-align: left;
        line-height: 41px;
        padding: 0 0 0 30px !important;
    }
}
.foot p:nth-child(1){
    font-size: 18px;
    color: #999;
    padding: 50px 0 0 0px;

}
.foot p:nth-child(2){
    font-size: 18px;
    color: #999;
    padding: 0 0 0 0px;

}
.foot1 p:nth-child(1){
    font-size: 18px;
    color: #999;
    padding: 50px 0 0 0px;

}
.foot1 p:nth-child(2){
    font-size: 18px;
    color: #999;
    padding: 0 0 0 0px;

}
.lines{
    margin: 50px 0 0 100px;
    width: 1100px;
    height: 1px;
    background: #dedede;

}
.iptdiv{
    width: 650px;
    height: 54px;
    margin: 0 auto;
    margin-top: 50px;
    input{
        width: 400px;
        height: 54px;
        background: #FBFBFB;
        border: 1px solid #E6E6E6;
        border-radius: 10px 0 0 10px;
        color: #666;
        padding: 0 0 0 30px;
        outline: none;
        font-size: 20px;
    }
    button{
        width: 250px;
        height: 54px;
        background: #ee2e2e;
        color: #fff;
        border-radius: 0 10px 10px 0;
        border: none;
    }
}
.dhmmsg{
    font-size: 20px;
color: #666666;
padding: 10px 0 0 0px;
}
.gxmsg{
    font-size: 30px;
    color: #666;
    padding: 30px 0 0 0px;
}
.tline{
  width: 1100px;
  height: 1px;
  margin: 0 0 0 100px;
  background: #dedede;
  margin-top: 30px;
}
.line{
    width: 1200px;
    // float: left;
    margin-left: 0px;
    img{
        padding-top: 30px;
    }
}
.phoneMsg{
    position: absolute;
    left: 44.5%;
    top: -27%;
}
.bbtn{
    width: 250px;
    height: 54px;
    margin-left: 70px;
    font-size: 20px;
}
.mipt{
    width: 680px;
    height: 54px;
    background:  #FBFBFB;
    display: flex;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    p{
        width: 113px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        color: #666;
        border-right: 1px solid #d8d8d8;
    }
    input{
    background: #FBFBFB;
    border: none;
    outline: none;
    padding: 0 0 0 30px;
    }
}
.iptbtn{
    margin: 60px 0 0 0px;
    display: flex;
}
.linered{
    margin: 80px 0 0 0 ;
    position: relative;
    p{
        font-size: 22px;
        color: #666;

    }
}
.main_boxcenter{
    margin: 50px 0 0 0px;
    display: flex;
    .name{
        font-size: 22px;
        color: #666;
        width: 635px;
        padding: 0 0 0 30px;
        text-align: left;
    }
    .price{
        color: #666;
        font-size: 26px;
        text-align: left;
        padding: 62px 0 0 30px;
    }
    .lqbtn{
        display: block;
        text-align: left;
        margin: 30px 0 0 30px;
    }
}
.commodity{
    text-align: left;
    img{
        width: 335px;
        height: 239px;
    }
}
.main_boxtop{
    p{
        height: 20px;
        line-height: 20px;
        display: inline-block;
        color: #333;
        font-size: 18px;
        font-weight: 600;
        border-left: 3px solid #ee2e2e;
        padding: 0px 0 00px 12px;
        margin: 52px 0 0px 100px;
        text-align: left;
        display: block;
        position: relative;
        // border-bottom: 1px solid #DEDEDE;
    }

}
/deep/ .el-button{
    background: #ee2e2e;
}
.main_box{
    margin: 0 auto;
    margin-top: 80px;
    width: 1200px;
    height: 967px;
    background: #fff;
    overflow: hidden;
}
.head{
    box-shadow: 0 0px 0px 0px !important;
}
</style>
